<template>
    <a-layout style="background:#fff;padding:14px 20px;">
        <a-table bordered :columns="columns" rowKey="ID" :data-source="MaterialReadDetail" :pagination="pagination" @change="pagerChange">
            <span slot="name_slot" slot-scope="text,record">
                {{record.VisterName}}{{record.IsNewUser==0?"":" (拉新)"}}
            </span>
        </a-table>
    </a-layout>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "Material_ReadList",
        data() {
            return {
                MaterialReadDetail: [],
                columns: [
                    {
                        title: "头像",
                        width: 280,
                        align: "center",
                        customRender: (text, item) => {
                            var img = item.VisterImg;
                            if (img != null && img != "") {
                                return <div><img src={img} width="60" height="60" /></div>
                            } else {
                                return <div><img src="/image/tjwz.png" width="60" height="60" /></div>
                            }
                        }
                    },
                    {
                        title: "姓名",
                        width: 280,
                        scopedSlots: { customRender: "name_slot" },
                    },
                    {
                        title: "分享人",
                        dataIndex: "ShareName",
                        width: 280
                    },
                    {
                        title: "介绍人",
                        dataIndex: "IntroducerName",
                        width: 280
                    },
                    {
                        title: "阅读时间",
                        width: 360,
                        customRender: (text, item) => {
                            var temp = new Date(util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss'));
                            return util.formatDate3(temp);
                        }
                    }
                ],
                Tablelocale: {
                    emptyText: "暂无数据"
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                }, config: {
                    rules: [
                        { type: "object", required: true, message: "Please select time!" }
                    ]
                },
                rangeConfig: {
                    rules: [
                        { type: "array", required: true, message: "Please select time!" }
                    ]
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PublishStatus: "-1",
                MaterialType: "-1",
                VisterName: "",
                BeginTime: "",
                EndTime: "",
                InputTime: []
            };
        },
        methods: {
            //查询事件
            QueryData() {
                if (this.InputTime.length > 0) {
                    this.BeginTime = util.formatDate(this.InputTime[0]._d);
                    this.EndTime = util.formatDate(this.InputTime[1]._d);
                }
                this.pagination.current = 1;
                this.loadData();
            },
            //时间选择监控事件
            handleChange(value) {
                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                }
                this.InputTime = value;
            },
            //页面跳转事件
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            loadData() {
                var self = this;
                console.log(this.$route.query.IsNewUser);
                var op = {
                    url: "/MarketingModule/Material/GetMaterialReadList",
                    data: {
                        ID: this.$route.query.ID,
                        VisterName: self.VisterName,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                        PageIndex: self.pagination.current,
                        IntroducerID: this.$route.query.IntroducerID,
                    },
                    OnSuccess: function (res) {
                        self.MaterialReadDetail = res.data.Data;
                        console.log(self.MaterialReadDetail);
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
        },
        mounted() {
            this.loadData();
        },
        props: {
        },
    };
</script>



