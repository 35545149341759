<template>
  <a-layout>
    <div style="background-color:#fff;">
      <a-layout-content style="padding:0;">
        <div style=" background: #fff; padding:0px 20px 0; margin:0;">
          <a-tabs default-active-key="0" @change="radioChange">
            <a-tab-pane key="0" tab="阅读" />
            <a-tab-pane key="1" tab="团队" />
            <a-tab-pane key="2" tab="红包" />
            <a-tab-pane key="3" tab="优惠卷" />
            <a-tab-pane key="4" tab="打卡" v-if="InterActiondisplay" />
          </a-tabs>
          <div v-if="Commission==0">
            <ReadList />
          </div>
          <div v-if="Commission==1">
            <PartnerList />
          </div>
          <div v-if="Commission==2">
            <MaterialAwardDetail />
          </div>
          <div v-if="Commission==3">
            <PrizeSendDetail />
          </div>
          <div v-if="Commission==4">
            <InterActionDetail />
          </div>
        </div>
      </a-layout-content>
    </div>
  </a-layout>
</template>
<script>
import MaterialAwardDetail from "@/Views/MaterialModule/Store/MaterialAwardDetail";
import PartnerList from "@/Views/MaterialModule/Store/PartnerList";
import ReadList from "@/Views/MaterialModule/Material/ReadList";
import PrizeSendDetail from "@/Views/MaterialModule/Store/PrizeSendDetail";
import InterActionDetail from "@/Views/InteractionClockModule/PersonalList";
export default {
  name: "Store_MaterialTabs",
  data() {
    return {
      InterActiondisplay: false,
      Commission: "0",
      //ClassID:"0",
      // thispage:this.$route.query.page,
      // c_type:this.$route.query.c_type,
      // r_type:this.$route.query.r_type,
      // s_name:this.$route.query.s_name,
   
    };
  },
  props: {
    //组件属性
  },
  methods: {
    //方法
    radioChange: function(item) {
      var self = this;
      self.Commission = item;
    },
    isshowInterAction: function() {
      var self = this;
      if (this.$route.query.ClassID == 8) self.InterActiondisplay = true;
    }
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
    MaterialAwardDetail,
    PartnerList,
    ReadList,
    PrizeSendDetail,
    InterActionDetail
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    //this.MInterID = this.$route.query.MInterID;

    this.MaterialID = this.$route.query.ID;
    this.ClassID = this.$route.query.ClassID;
    this.isshowInterAction();
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>